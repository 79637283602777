import { useStorefrontBookingTest1 } from '@feature-flags/hooks/Storefront/useStorefrontBookingTest1';
import { Button } from '@xo-union/tk-component-buttons';
import classnames from 'classnames';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import type { Raw } from 'types/vendor';
import { useTrackBooking } from '../../../../hooks/use-track-booking';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet, isPostPrimary } from '../../utils';
import QuickResponderBadge from '../QuickResponderBadge/QuickResponderBadge';
import StickyCtaBoxCta from './components/StickyCtaBoxCta';
import StickyCtaBoxText from './components/StickyCtaBoxText';
import Styles from './styles.scss';

interface StateProps {
	viewport: Redux.Viewport;
	vendor: Raw;
	conversations: MessagedVendors.Conversations;
}

export interface OwnProps {
	context: ReturnType<typeof useInlineRfqForm>;
}

type InlineCtaBoxProps = StateProps & OwnProps;

export const StickyCtaBox: FC<InlineCtaBoxProps> = (props) => {
	const { viewport, context, vendor, conversations } = props;
	const isTablet = checkIsTablet(viewport);
	const isBookingTest1 = useStorefrontBookingTest1();
	const trackBookingClick = useTrackBooking(vendor, 'top_navigation_scroll');

	const { isModalOpen, shouldShowErrors, areErrorsInForm } = context;

	const shouldShowStickyText = isTablet || viewport.isMobile;
	const postPrimary = isPostPrimary(
		conversations,
		vendor.id,
		isModalOpen,
		shouldShowErrors,
		areErrorsInForm,
	);

	const shouldShowQuickResponder =
		Boolean(vendor.vendorBehavior?.quickResponder) && !postPrimary;

	return (
		<div
			className={classnames(Styles.box, {
				[Styles.tabletBox]: isTablet && !viewport.isMobile,
				[Styles.mobileBox]: !isTablet && viewport.isMobile,
			})}
			data-testid="button-box"
		>
			{shouldShowStickyText && <StickyCtaBoxText context={context} />}
			{shouldShowQuickResponder && (
				<div className={Styles.quickResponderBadgeWrapper}>
					<QuickResponderBadge />
				</div>
			)}
			{isBookingTest1 && (
				<Button onClick={trackBookingClick} block>
					Book
				</Button>
			)}
			<StickyCtaBoxCta context={context} />
		</div>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	viewport: state.viewport,
	vendor: state.vendor.vendorRaw,
	conversations: state.messagedVendors.conversations,
});

export default connect(mapStateToProps)(StickyCtaBox);
