import NavigableModal from '@components/NavigableModal/NavigableModal';
import { Button } from '@xo-union/tk-component-buttons';
import React, { useState } from 'react';
import { BookNow } from './BookNow';
import Styles from './BookNowContainer.scss';
import type { BookingAnalyticsProps } from './useBookNowAnalytics';

export const BookNowStickyCta = ({
	bookingAnalytics,
}: { bookingAnalytics: BookingAnalyticsProps }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<div className={Styles.bookNowMobile}>
				<Button onClick={() => setIsOpen(true)} block>
					Start booking
				</Button>
			</div>
			{isOpen && (
				<NavigableModal
					hash="book-now-modal"
					isModalOpen={isOpen}
					closeCallback={() => setIsOpen(false)}
				>
					<BookNow bookingAnalytics={bookingAnalytics} />
				</NavigableModal>
			)}
		</>
	);
};
