import analytics from '@constants/analytics';
import React from 'react';
import { BookNow } from './BookNow';
import Styles from './BookNowContainer.scss';
import { BookNowStickyCta } from './BookNowStickyCta';
import { storefrontAnalytics } from './useBookNowAnalytics';

export const BookNowContainer = () => {
	const bookingAnalytics = {
		...storefrontAnalytics,
		page_section: analytics.bookingPageSections.HEADER,
	};

	return (
		<>
			<div className={Styles.bookNowSidebar}>
				<BookNow bookingAnalytics={bookingAnalytics} />
			</div>
			<BookNowStickyCta bookingAnalytics={bookingAnalytics} />
		</>
	);
};
