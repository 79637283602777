import Calendar from '@components/calendar/Calendar';
import type { AvailabilityResponse } from '@components/calendar/types';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useAppSelector } from '@redux/hooks';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import { H4 } from '@xo-union/tk-ui-typography';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import React from 'react';
import type { State } from 'types/redux';
import RfqModal from '../../containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../containers/InlineRFQ/utils';
import RfqButton from '../../containers/cta_box/RfqButton';
import type { NavItemProps } from '../../containers/nav/types';
import { EmptyStateSearchLink } from '../EmptyStateSearchLink/EmptyStateSearchLink';
import { AvailabilityLegend } from './AvailabilityLegend';
import Styles from './AvailabilitySection.scss';
import { availabilityLoading } from './availability-atoms';
import { useTrackAvailabilityCalendar } from './hooks/use-track-availability-calendar';

const DATE_FORMAT = 'yyyy-MM-dd';

export type AvailabilitySectionProps = {
	availabilityData: AvailabilityResponse | null;
};

export interface AvailabilitySectionEmptyStateProps extends NavItemProps {
	title: string;
}

export const AvailabilitySectionEmptyState = () => (
	<>
		<H4 className={Styles.sectionTitle} data-testid="availability-empty">
			Availability
		</H4>
		<EmptyStateSearchLink
			header="No availability details yet"
			text="Looking for the perfect match?"
			linkText="Continue browsing vendors"
			storefrontSection="Availability"
		/>
	</>
);

const AvailabilitySection = ({
	availabilityData,
	className,
}: AvailabilitySectionProps & NavItemProps) => {
	const formContext = useInlineRfqForm({
		initiator: 'Availability Calendar CTA',
	});

	const isEmptyStates = useStorefrontEmptyStates();
	const isLoading = useAtomValue(availabilityLoading);
	const shouldShowAvailabilityEmptyStates = isEmptyStates && !availabilityData;
	const weddingDate =
		useAppSelector((state: State) => state?.membership?.member?.wedding_date) ||
		null;
	const trackAvailabilityCalendar = useTrackAvailabilityCalendar();

	const renderRfqButton = () => (
		<RfqButton
			key="availability-rfq-button"
			size="sm"
			color="secondary"
			label="Ask about a specific date"
			handleCTAClick={formContext.openModal}
			styleName={Styles.availabilityRfqButton}
		/>
	);

	const handleOnDayClick = (day) => {
		const dayFormatted = format(day, DATE_FORMAT);
		const weddingDateValue = formContext.values.weddingDate;

		setTimeout(() => {
			handleFieldChange({
				cb: formContext.setFieldValue,
				data: weddingDateValue,
				name: 'weddingDate',
				value: dayFormatted,
			});
		}, 10);

		formContext.openModal();
	};

	if (!isEmptyStates && !availabilityData) {
		return null;
	}

	if (isLoading) {
		return (
			<>
				<H4 className={Styles.sectionTitle}>Availability</H4>
				<Skeleton>
					<Bone style={{ height: 318, width: '100%', borderRadius: 8 }} />
				</Skeleton>
			</>
		);
	}

	return shouldShowAvailabilityEmptyStates ? (
		<AvailabilitySectionEmptyState />
	) : (
		<div className={classNames(Styles.availabilitySectionContainer, className)}>
			<RfqModal context={formContext} />
			<H4 className={Styles.sectionTitle} data-testid="availability">
				Availability
			</H4>
			<Calendar
				availabilityData={availabilityData}
				onDayClick={(day) => handleOnDayClick(day)}
				weddingDate={weddingDate}
				trackCalendar={trackAvailabilityCalendar}
			/>
			<AvailabilityLegend />
			<Body1 className={Styles.sectionCaption}>
				Reach out to this vendor to confirm their availability, as recent
				changes may not be reflected in this calendar.{' '}
			</Body1>
			{renderRfqButton()}
		</div>
	);
};

export default AvailabilitySection;
